import React from 'react'
import styled from 'styled-components';
import { css } from 'emotion';
import tw from 'tailwind.macro';
import Layout from '../components/Layout';
import NavBar from '../components/NavBar';
import { GlobalStyle } from '../pages/HomePage';
import Row from '../components/Row';
import { Waypoint } from 'react-waypoint';
import { Link, graphql } from 'gatsby';
import Image from '../components/Image';
import Menu from '../components/Menu';
import Prismic from 'prismic-javascript';
import Card from '../components/Card';
import AdCard from '../components/AdCard';
import DesktopGalleryAdCard from '../components/DesktopGalleryAdCard';
import MobileGalleryAdCard from '../components/MobileGalleryAdCard';
import Moment from 'moment';
import SEO from '../components/SEO'
import AdCardInGalleryPage from '../components/AdCardInGalleryPage';
import {isMobile} from 'react-device-detect';
import Adsense from 'react-adsense';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-141669883-1');
const apiEndpoint = 'https://prad.prismic.io/api/v2';

const SectionOne = styled.section`
    ${tw`flex flex-wrap relative mt-0 w-screen items-start z-0`};
    &:after{
        content: '';
        position: absolute;
        z-index: -1;
        display: block;
        height: 75vh;
        width: 100vw;
        ${tw`bg-black`};
    }
`
const SectionTwo = styled.section`
    ${tw`min-h-screen h-auto relative z-0 bg-grey-lightest overflow-show`};
`
const PicturesScroll = styled.div`
    ${tw`relative mx-auto z-10 pin-x pin-t`};
    @media(min-width : 992px){
        // margin-top: -20rem;
    }
`

const Title = styled.h1`
    ${tw`font-semibold text-4xl lg:w-2/3 w-4/5 px-2 lg:px-6`}
    color: #F2F4F7;
    // letter-spacing: -.57px;
`
const BannerImageWrapper = styled.div`
    ${tw`w-screen relative overflow-hidden`};
    height: 25rem;
    ${props => `background-image: url(${props.image})`};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`
const Paragraph = styled.p`
    ${tw`my-8`}
    opacity: 0.77;
    font-family: HelveticaNeue;
    // font-size: 23px;
    color: #2D2D2E;

    letter-spacing: 0.46px;
    line-height: ${1.618 / 0.809};
`

class Article extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            feedInView: false,
            showMenu: false,
            invertNav: false,
            feedCategory: 'all',
            fixCategoryNav: false,
            showSearch: false,
            relatedArticles: [],
            city: '',
            temp: '',
            searchResults: [],
            noResultsFound: false
        }
        this.sectionOneEnd = this.sectionOneEnd.bind(this);
        this.toggleShowMenu = this.toggleShowMenu.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }


    sectionOneEnd() {
        // alert('Section One End');
        this.setState({ feedInView: true, invertNav: true });
    }

    toggleShowMenu(type) {
        let that = this;
        this.setState({
            showMenu: !this.state.showMenu,
            invertNav: !this.state.invertNav,
        },
            function () {
                //console.log('state Change: ' + JSON.stringify(this.state));
                if (type == 'search') {
                    //console.log('Search Clicked');
                    this.setState({ showSearch: true });
                }
                else {
                   // console.log('Menu Clicked');
                    this.setState({ showSearch: false });
                    return;
                }
            }
        )
    }

    onCloseClick() {
        this.setState({
            showMenu: !this.state.showMenu,
            invertNav: !this.state.invertNav,

        });
    }

    handleKeyPress(e, text) {
        let self = this;
        if (e.key === 'Enter') {
            Prismic.api(apiEndpoint).then(api => {
                //debugger
                api.query([
                    Prismic.Predicates.at('document.type', 'article'),
                    Prismic.Predicates.fulltext('document', text)
                ]).then(function (response) {
                    //debugger
                    if (response.results.length == 0) {
                        self.setState({ noResultsFound: true });
                    }
                    self.setState({ searchResults: response.results, noResultsFound: false });

                    // response is the response object, response.results holds the documents
                });
            });
        }
    }

    componentDidMount(){
        const ele = document.getElementById('ipl-progress-indicator')
        if(ele){
            ele.outerHTML = '';
        }
		
        let self = this;
        let tags = this.props.data.prismicGalleryarticle.tags;
        let filteredTags = tags.filter((val, index, arr) => {
            return val != 'featured'
        })
        Prismic.api(apiEndpoint).then(api => {
            //debugger
            api.query(
                Prismic.Predicates.any('document.tags', filteredTags),
                { orderings: '[document.last_publication_date desc]'}
            ).then(function (response) {
                //console.log(response.results);
                self.setState({ relatedArticles: response.results });
                // response is the response object, response.results holds the documents
            });
        });
    }

    render() {
        //console.log(this.props.data);
        let uidPath = this.props.data.prismicGalleryarticle.uid;
        ReactGA.pageview(uidPath);
        ReactGA.event({
            category: 'Gallery',
            action: 'Card Click'
        });
        let article = this.props.data.prismicGalleryarticle.data;
        return (

            <>
                {/* <SEO
                    title={article.title.text}
                    lang={"en"}
                    metaDescription={article.content.raw.text}
                    meta={[]}
                    keywords={[]}
                />  */}
                <GlobalStyle />
                <NavBar dark={this.state.feedInView || this.state.invertNav}
                    menuClick={() => this.toggleShowMenu('menu')}
                    onSearchClick={() => this.toggleShowMenu('search')}
                    onCloseClick={this.onCloseClick}
                    showClose={this.state.showMenu} />
                <Menu 
                    active={this.state.showMenu} 
                    showSearch={this.state.showSearch} 
                    handleKeyPress={this.handleKeyPress}
                    searchResults={this.state.searchResults} 
                    noResultsFound={this.state.noResultsFound}
                />
                <Layout>

                    <SectionOne>
                        <Row className={css`margin-top: 6rem;`}>
                            <Adsense.Google
                                            client='ca-pub-5630104718741028'
                                            slot='9699731559'
                                            style={{marginLeft:"auto",marginRight:"auto",display: "block",height: 90, width: 728 }}
                                            format='auto'
                                            responsive='true'
                                />                             
                            <div className={css`${tw`w-full`}`}>
                                <Title>
                                    {article.title.text}
                                </Title>
                            </div>
                        </Row>

                        
                        <Waypoint onEnter={() => this.setState({ feedInView: false })} onLeave={() => this.setState({ feedInView: true })} scrollableAncestor={'window'} />
                        <PicturesScroll>
                            <Row>
                                {article.group.map((a, i) => {
                                    if(!isMobile && ((i+1) % 4 == 0)){
                                        return (
                                            <>
                                                <div className={css`${tw`lg:w-1/2 px-6 -mx-2 w-full ${i % 2 == 0 && 'mt-16'}`}`}>
                                                    <DesktopGalleryAdCard
                                                        client='ca-pub-5630104718741028'
                                                        slot='9368762673'
                                                        style={{display: "block",width: "100%"}}
                                                        layout={"-6t+ed+2i-1n-4w"}
                                                        format={"fluid"}
                                                    />                                                    
                                                </div>
                                                <div className={css`${tw`lg:w-1/2 px-6 -mx-2 w-full ${i % 2 == 0 && 'mt-16'}`}`}>
                                                    <img src={a.img.url} style={{width: "100%", height: 'auto', borderRadius: 16 }} />
                                                </div>
                                            </>
                                      )
                                    }else{
                                        return (
                                            <div className={css`${tw`lg:w-1/2 px-6 -mx-2 w-full ${i % 2 == 0 && 'mt-16'}`}`}>
                                                <img src={a.img.url} style={{ width: "100%", height: 'auto', borderRadius: 16 }} />
                                            </div>
                                        )
                                    }
                                })}
                            </Row>
                        </PicturesScroll>
                    </SectionOne>

                    <SectionTwo>
                        
                        <Row>
                            <div className={css`${tw`flex flex-wrap lg:w-4/5 w-full`}`}>
                                <h3 className={css`${tw`w-full`}`}>You may also like these</h3>
                                {this.state.relatedArticles.map((a,i) => {
                                    if (a.uid != this.props.data.prismicGalleryarticle.uid){
                                        if ((i + 1) % 4 == 0){
                                        return (
                                            <>
                                                <AdCard
                                                    client='ca-pub-5630104718741028'
                                                    slot='9368762673'
                                                    style={{ display: "block",height: 350, width: 350 }}
                                                    layout={"-6t+ed+2i-1n-4w"}
                                                    format={"fluid"}
                                                />
                                                <Card
                                                    feed
                                                    related
                                                    title={a.data.title[0].text}
                                                    img={a.data.hasOwnProperty("img") && a.data.img.hasOwnProperty("url") ? a.data.img.url : ""}
                                                    date={Moment(a.data.datetime).format("DD MMMM, YYYY")}
                                                    time={Moment(a.data.datetime).format("h A")}
                                                    uid={a.uid}
                                                    category={a.data.categories ? a.data.categories[0].category.slug : ""}
                                                />
                                            </>
                                    )
                                    }
                                    else {
                                        return (
                                            <Card
                                                feed
                                                related
                                                title={a.data.title[0].text}
                                                img={a.data.hasOwnProperty("img") && a.data.img.hasOwnProperty("url") ? a.data.img.url : ""}
                                                date={Moment(a.data.datetime).format("DD MMMM, YYYY")}
                                                time={Moment(a.data.datetime).format("h A")}
                                                uid={a.uid}
                                                category={a.data.categories ? a.data.categories[0].category.slug : ""}
                                            />
                                        )
                                    }


                                        // return (
                                        //     <Card
                                        //         feed
                                        //         related
                                        //         title={a.data.title[0].text}
                                        //         img={a.data.hasOwnProperty("img") && a.data.img.hasOwnProperty("url") ? a.data.img.url : ""}
                                        //         date={Moment(a.data.datetime).format("DD MMMM, YYYY")}
                                        //         time={Moment(a.data.datetime).format("h A")}
                                        //         uid={a.uid}
                                        //         category={a.data.categories ? a.data.categories[0].category.slug : ""}
                                        //     />
                                        // )
                                    }
                                }
                                    
                                )}
                                {/* <Card feed related/> */}
                                {/* <AdCard/> */}
                            </div>
                        </Row>
                    </SectionTwo>
                </Layout>
            </>

        );
    }
}

export default Article;

export const pageQuery = graphql`
query GalleryArticleBySlug($uid: String!) {
   prismicGalleryarticle(uid:{ eq: $uid }) {
      uid
      tags
      data {
        img{
            url
        }
        group {
            img{
                url
            }
        }
        title {
          text
        }
        content{
            raw{
                text
            }
        }
      }
    }
}
`





// query{
//     allPrismicGalleryarticle{
//         edges{
//             node{
//                 data{
//                     group{
//                         img{
//                             url
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }